import { Button }    from "@mantine/core";
import dynamic       from "next/dynamic";
import { useRouter } from "next/router";
import { useState }  from "react";

import { storeEvent }                         from "_analytics/index";
import { useGetLoggedIn, useGetProfileData }  from "_common/hooks/auth";
import { useGetDeviceType, useSetLoginPopup } from "_common/hooks/global";
import { useSetBroadcastId }                  from "_common/hooks/stream";
import Toast                                  from "_common/toast";
import {
	getBaseURL,
	isPageRoute
} from "_common/utils";
import { copyToClipboard }        from "_common/utils/clientSide";
import { PrimaryButton }          from "_components/atoms/button";
import Icon                       from "_components/atoms/icon";
import Render                     from "_components/atoms/render";
import Typography                 from "_components/atoms/typography";
import ConfirmModal               from "_components/molecules/confirmModal";
import HeaderAvatarMenu           from "_components/molecules/headerAvatarMenu";
import CreateButton               from "_components/organisms/createButton";
import SearchModal                from "_components/organisms/header/components/searchModal";
import {
	useGetCategoryTab,
	useGetStreamParams,
	useGetToggleIsStreamPreviewing,
	useSetIsStreamEnded,
	useSetStreamHealthCheckModal,
	useSetStreamSummary,
	useSetToggleStreamPreviewVideo,
	useSetToggleStreamSummaryModal
} from "_components/pages/studio/states";
import { isValidatedCreateLiveParams } from "_components/pages/studio/utils";
import {
	useGetUpdateSession,
	useSetUpdateSession
} from "_jotai/liveSession";
import {
	activateFanRankLeaderboard,
	endLiveBroadcast,
	startLiveBroadcast,
	verifySessionStatus
} from "_services/api/broadcast";
import useStyles from "_styles/organisms/header";

import StreamHealthCheckModal from "../streamHealthCheckModal";

const NotificationTab = dynamic ( () => import ( "_components/organisms/notificationTab" ) );

const Component = ( { showShareIcon, isMobile, isCreatorDashboard } ) => {
	const router     = useRouter ();
	const deviceType = useGetDeviceType ();
	const isTablet   = deviceType === "tablet";

	const isMobileAndTablet = isMobile || isTablet;

	// const [ showAppearanceMenu, setShowAppearanceMenu ] = useState ( false );
	const [ anchorEl, setAnchorEl ]               = useState ( "" );
	const [ showSearchModal, setShowSearchModal ] = useState ( false );
	const [ starting, setStarting ]               = useState ( false );
	const [ ending, setEnding ]                   = useState ( false );

	const userProfile                 = useGetProfileData ();
	const isStreamPreviewing          = useGetToggleIsStreamPreviewing ();
	const setStreamHealthCheckModal   = useSetStreamHealthCheckModal ();
	const liveParams                  = useGetStreamParams ();
	const sessionData                 = useGetUpdateSession ();
	const isUserLoggedIn              = useGetLoggedIn ();
	const irlCheck                    = useGetCategoryTab () === "irl";
	const setLoginPopup               = useSetLoginPopup ();
	const setIsStreamEnded            = useSetIsStreamEnded ();
	const updateSession               = useSetUpdateSession ();
	const setToggleStreamPreviewVideo = useSetToggleStreamPreviewVideo ();
	const setToggleStreamSummaryModal = useSetToggleStreamSummaryModal ();
	const setStreamSummary            = useSetStreamSummary ();
	const setBroadcastId              = useSetBroadcastId ();

	const isSessionLive = sessionData && !sessionData.endTimeUTC;

	const isStudioPage = isPageRoute ( { router, page: "studio" } );

	/* const isStreamPage        = isPageRoute ( { router, page: "stream" } );
   * const isNotificationsPage = isPageRoute ( { router, page: "notifications" } ); */

	const isCreatorDashboardPage = isPageRoute ( { router, page: "creator-dashboard" } );
	// if ( !isUserLoggedIn )
	// return null;

	// if ( isStudioPage || isCreatorDashboardPage )
	// return null;

	const checkToShowNotificationTab = () => {
		if ( isStudioPage || isCreatorDashboardPage )
			return false;

		if ( !isUserLoggedIn )
			return false;

		return true;
	};

	const showNotificationTab = checkToShowNotificationTab ();

	const showCreateButton = !isStudioPage;

	const { classes } = useStyles ( { starting, ending, isStudioPage }, { name: "header-menu-items" } );

	function handleGoLive () {
		if ( !isSessionLive ) {
			setStreamHealthCheckModal ( true );
		}
	}

	const startLiveBroadcastAction = async ( { liveParams, irlCheck } ) => {
		if ( !isValidatedCreateLiveParams ( liveParams ) )
			return;

		setStarting ( true );

		const videoElem = document.getElementsByTagName ( "video" );
		let resolution  = "1920,1080";

		if ( videoElem && videoElem.length ) {
			const { videoWidth, videoHeight } = videoElem[0];

			resolution = videoHeight && videoWidth ? `${ videoWidth },${ videoHeight }` : "1920,1080";
		}

		try {
			const result = await verifySessionStatus ( { cdnUrl: liveParams?.rtmpDetails?.cdnUrl } );

			if ( result.sessionAlreadyExists && !result.multipleStreamEnabled ) {
				setStarting ( false );

				return Toast.warn ( "You already have an active session from another device" );
			}
		} catch ( error ) {
			setStarting ( false );

			return Toast.error ( error.displayMsg );
		}

		const { title, streamDescription, androidPackageName, streamLanguage, game } = liveParams?.streamDetails;
		const { streamKey, streamName, ivsChannelId }                                = liveParams?.rtmpDetails;

		const { donationGoalAmount } = liveParams?.donationGoal;

		const params = {
			title              : title || "",
			description        : streamDescription || "",
			androidPackageName : androidPackageName || "",
			streamKey          : streamKey,
			streamName         : streamName,
			ivsChannelId       : ivsChannelId,
			locale             : streamLanguage.localeKey,
			mediaType          : "video",
			sessionType        : irlCheck ? "IRL" : "gaming",
			gameName           : game?.name,
			resolution         : resolution,
			thumbnailUrl       : liveParams?.thumbnailDetails?.thumbnailUrl || null,
			recordStream       : liveParams?.recordStream || 0,
			donationGoal       : parseInt ( donationGoalAmount ),
			followerOnlyChat   : !!liveParams?.isFollowerOnlyChat,
			donorOnlyChat      : !!liveParams?.isDonorOnlyChat,
			// overlayUrl         : liveParams.overlayUrl, // not sure if this is needed for successfull api call
			tagIds             : liveParams?.streamDetails?.tagsList.map ( tag => tag.tagId ) // not sure where this is coming from, need to add schema for this
		};

		try {
			/*
       * Activating fankrank before starting the stream
       * in case fankrank is not activated, we won't start the stream
       * this is avoid the issue of fanrank not activated sometimes
       */
			if ( liveParams?.fanRank?.fanRankStatus && liveParams?.fanRank?.fanRankAmount && !liveParams?.sameDayFanLeaderBoard )
				await activateFanRankLeaderboard ( { fanRankAmount: parseInt ( liveParams?.fanRank?.fanRankAmount ) } );

			let sData = await startLiveBroadcast ( params );

			storeEvent ( "broadcast_created", "RTMP", "web" );
			updateSession ( sData );
			setStarting ( false );
			setBroadcastId ( sData.id );
			setToggleStreamPreviewVideo ( false );
			setIsStreamEnded ( false );
			Toast.info ( "You are live now." );
		} catch ( error ) {
			setStarting ( false );

			return Toast.error ( error.displayMsg );
		}
	};

	const endLiveBroadcastAction = async ( { sessionData } ) => {

		if ( !sessionData || sessionData.endedAt )
			return;

		setEnding ( true );

		try {
			const streamSummary = await endLiveBroadcast ( {
				broadcastSessionId: sessionData.id || sessionData.broadcastSessionId
			} );

			setEnding ( false );
			setToggleStreamSummaryModal ( true );
			updateSession ( null );
			setStreamSummary ( streamSummary );
			setBroadcastId ( undefined );
			setIsStreamEnded ( true );
		} catch ( error ) {
			setEnding ( false );

			return Toast.error ( error.displayMsg );
		}
	};

	const toggleLogin = () => {
		setLoginPopup ( true );
	};

	const onShareHandler = () => {
		if ( !isSessionLive ) {
			Toast.error ( "Stream is not live! Cannot copy stream URL." );

			return;
		}
		if ( sessionData?.id ) {
			const baseURL = getBaseURL ();

			copyToClipboard ( `${ baseURL }/stream/${ sessionData.id }` );
		}
	};

	const toggleSearchModal = () => {
		setShowSearchModal ( prevState => !prevState );
	};

	if ( !isUserLoggedIn ) {
		if ( isCreatorDashboard ) {

			return (
				<div className = { classes.loginContainer }>

					<HeaderAvatarMenu
						hideBackBtn
						isLogout = { true }
						isMobile = { isMobile }
						onCloseOutside = { () => setAnchorEl ( "" ) }
						outsideAnchorEl = { anchorEl }
						// // setShowAppearanceMenu = { setShowAppearanceMenu }
						// showAppearanceMenu    = { showAppearanceMenu }
						userProfile = { userProfile }
					/>

					<PrimaryButton
						buttonTextClassName = { classes.authBtnText }
						className = { classes.authButtons }
						onClick = { toggleLogin }
						title = { "Login / Sign Up" }
					/>

				</div>
			);
		}

		return (
			<div className = { classes.loginContainer }>

				<HeaderAvatarMenu
					hideBackBtn
					isLogout = { true }
					isMobile = { isMobile }
					onCloseOutside = { () => setAnchorEl ( "" ) }
					outsideAnchorEl = { anchorEl }
					// setShowAppearanceMenu = { setShowAppearanceMenu }
					// showAppearanceMenu    = { showAppearanceMenu }
					userProfile = { userProfile }
				/>

				<PrimaryButton
					buttonTextClassName = { classes.authBtnText }
					className = { classes.authButtons }
					onClick = { toggleLogin }
					title = { "Login / Sign Up" }
				/>

			</div>
		);
	}

	// if user is logged in
	return (
		<div className = { classes.loginContainer }>

			<Render condition = { isStudioPage }>
				<Render condition = { isSessionLive }>
					<ConfirmModal
						acceptButtonText = "End"
						buttonComponent = { (
							<Button
								className = { `${ classes.endStreamBtn } capitalize` }
								color = "red"
								// disabled = { ending }
								disableRipple = { ending }
								onClick = { e => ending && e.stopPropagation () }
								variant = "outline"
							>
								<Typography
									className = { `${ classes.endBtnText } fontBold` }
									color = "error"
									title = { ending ? "Ending" : "End Stream" }
								/>

							</Button>
						) }
						closeAfterActionPreformed = { true }
						dialogText = { "Are you sure, you want to end this stream? You will no longer be live." }
						dialogTitle = { "End Stream" }
						performAction = { () => endLiveBroadcastAction ( { sessionData } ) }
						rejectButtonText = "Cancel"
						// rtmpPage = { true }
					/>
				</Render>

				<Render condition = { !isSessionLive }>
					<Button
						className = { `${ classes.goLiveBtn } capitalize` }
						// color     = "green"
						disabled = { !isStreamPreviewing || starting }
						onClick = { handleGoLive }
						variant = "filled"
					>

						<Typography
							className = { `${ classes.goLiveText } fontBold colorWhite` }
							color = "textSecondary"
							title = { starting ? "Going Live" : "Go Live" }
						/>

					</Button>

					<StreamHealthCheckModal
						ivsChannelId = { liveParams?.rtmpDetails?.ivsChannelId }
						onSessionVerify = { () => startLiveBroadcastAction ( { liveParams, irlCheck } ) }
					/>
				</Render>
			</Render>

			<div className = { classes.topRightContainer }>

				<Render condition = { showCreateButton }>
					<div className = { classes.createButtonContainer }>
						<CreateButton
							buttonClass = { classes.createButton }
						/>
					</div>
				</Render>

				<Render condition = { showShareIcon }>
					<div onClick = { onShareHandler }>

						<Icon sameInBothTheme
							className = { classes.studioShareIcon }
							name = "studioShareIcon"
						>
						</Icon>
					</div>
				</Render>

				<Render condition = { isMobileAndTablet && !isCreatorDashboard }>
					<Icon
						className = { classes.searchIcon }
						name = { "search" }
						onClick = { toggleSearchModal }
					/>

					<SearchModal
						showSearchModal = { showSearchModal }
						toggleSearchModal = { toggleSearchModal }
					/>
				</Render>

				<Render condition = { showNotificationTab }>
					<NotificationTab />
				</Render>

				<HeaderAvatarMenu
					isLogout = { false }
					isMobile = { isMobile }
					userProfile = { userProfile }
				/>
			</div>
		</div>
	);
};

export default Component;
