import { useSetState }         from "@mantine/hooks";
import { useEffect, useState } from "react";

import { storeEvent }       from "_analytics/index";
import { useGetLoggedIn }   from "_common/hooks/auth";
import { useSetLoginPopup } from "_common/hooks/global";
import {
	UPLOAD_TYPE_CONFIG,
	uploadingStates,
	useGetInProcessingItem,
	useGetInProgressVideoDetails,
	useGetIsUploadingVideo,
	useGetUploadInProgressModal,
	useGetUploadTypeAtom,
	useGetVideoModal,
	useSetUploadBtnAtom,
	useSetUploadInProgressModal,
	useSetUploadTypeAtom,
	useSetVideoModal
} from "_common/hooks/uploadVideo";
import Toast                            from "_common/toast";
import { langArray }                    from "_components/organisms/localeSelector/utils";
import { getBroadcastSessionsByUserId } from "_services/api/broadcast";

import Presentation from "./presentation";

const isUploadDisabled = ( type, inProcessingItem ) => {

	return inProcessingItem?.[type]?.type === type
    && ( inProcessingItem?.[type]?.status === "INITIATED"
      || inProcessingItem?.[type]?.status === "PENDING" );
};

const component = props => {
	const {
		multiStream,
		onCreateNew,
		onGoLive,
		selectStream,
		checkSessionAPI,
		sessionData,
		isFetchingSessionStatus,
		showCurrentStreamMenu,
		buttonClass
	} = props;

	const [ anchorEl, setAnchorEl ]   = useState ( null );
	const [ modalOpen, setModalOpen ] = useState ( false );

	const setLoginPopup            = useSetLoginPopup ();
	const isUploadingVideo         = useGetIsUploadingVideo ();
	const uploadType               = useGetUploadTypeAtom ();
	const isLoggedIn               = useGetLoggedIn ();
	const openVideoModalExternal   = useGetVideoModal ();
	const setVideoModal            = useSetVideoModal ();
	const setUploadType            = useSetUploadTypeAtom ();
	const setUploadInProgressModal = useSetUploadInProgressModal ();
	const uploadInProgressModal    = useGetUploadInProgressModal ();
	const inProgressVideoDetails   = useGetInProgressVideoDetails ();
	const setUploadBtn             = useSetUploadBtnAtom ();
	const inProcessingItem         = useGetInProcessingItem ();

	const disableCreateReels  = isUploadDisabled ( UPLOAD_TYPE_CONFIG.REELS, inProcessingItem );
	const disableCreateVideos = isUploadDisabled ( UPLOAD_TYPE_CONFIG.VIDEOS, inProcessingItem );

	const [ state, setState ] = useSetState ( {
		isFetching : true,
		streams    : [],
		pageNo     : 1,
		isDepleted : false
	} );

	useEffect ( () => {
		if ( multiStream )
			fetchStreams ();
	}, [ multiStream ] );

	const mapLang = data => {
		return data.reduce ( ( acc, item ) => {
			acc[item.locale] = item.eName;

			return acc;
		}, {} );
	};

	const fetchStreams = async () => {
		setState ( { isFetching: true } );

		const { pageNo, streams } = state;

		try {
			const res = await getBroadcastSessionsByUserId ( { pageNo, pageSize: 10 } );

			const langMap = mapLang ( langArray );

			const __res = res.map ( data => {
				return {
					id     : data.id,
					image  : data.thumbnail,
					name   : data.sessionInfo,
					locale : langMap[data.localeKey]
				};
			} );

			setState ( {
				isFetching : false,
				streams    : [ ...streams, ...__res ],
				pageNo     : pageNo + 1,
				isDepleted : res.length ? false : true
			} );
		} catch ( error ) {
			setState ( { isFetching: false } );
			Toast.error ( "Error fetching sessions list" );
		}
	};

	const handleModalOpen = () => {
		setModalOpen ( true );
	};

	const handleModalClose = () => {
		setModalOpen ( false );
	};

	const open = Boolean ( anchorEl );

	const handleClick = event => {
		if ( open ) {
			setModalOpen ( false );

			return;
		}
		if ( !isLoggedIn )
			return setLoginPopup ( false );

		checkSessionAPI ();

		setAnchorEl ( event.currentTarget );

	};

	const handleClose = () => {
		storeEvent ( "UGC_creation_started", "Navigated_to", "Cross" );
		setAnchorEl ( null );
	};

	const handleNewMultiStream = () => {
		storeEvent ( "Side_Nav", "Clicked", "Go_Live" );
		onCreateNew ();
		handleClose ();
	};

	const handleStream = () => {
		storeEvent ( "Side_Nav", "Clicked", "Go_Live" );
		storeEvent ( "UGC_creation_started", "Navigated_to", "Broadcast" );
		onCreateNew ();
		handleClose ();
	};

	const openVideoModal = () => {
		setVideoModal ( true );
	};

	const closeVideoModal = () => {
		setVideoModal ( false );
	};

	const handleVideoModal = type => {
		setAnchorEl ( null );
		if (
			isUploadingVideo === uploadingStates.UPLOADING
      || inProgressVideoDetails?.status === "INITIATED"
      || disableCreateReels
      || disableCreateVideos
		) {
			// if ( type === inProcessingItem?.[type]?.type ) {
			setUploadInProgressModal ( true );

			return;
			// }

		}
		storeEvent ( "UGC_creation_started", "Navigated_to", type );
		openVideoModal ();
		handleClose ();
		setUploadType ( type );
		setUploadBtn ( type );
	};

	/* const UploadingVideoHandler = () => {
    Toast.info ( "Please wait! Upload is in progress." );
  }; */
	const gotoCurrentStudioStream = () => {
		window.open ( "/studio", "_blank" );
	};

	const {
		streams,
		isFetching,
		isDepleted
	} = state;

	return (
		<Presentation
			buttonClass = { buttonClass }
			closeVideoModal = { closeVideoModal }
			fetchStreams = { fetchStreams }
			gotoCurrentStudioStream = { gotoCurrentStudioStream }
			handleClick = { handleClick }
			handleClose = { handleClose }
			handleModalClose = { handleModalClose }
			handleModalOpen = { handleModalOpen }
			handleNewMultiStream = { handleNewMultiStream }
			handleStream = { handleStream }
			handleVideoModal = { handleVideoModal }
			isDepleted = { isDepleted }
			isFetching = { isFetching }
			isFetchingSessionStatus = { isFetchingSessionStatus }
			isLoggedIn = { isLoggedIn }
			modalOpen = { modalOpen }
			multiStream = { multiStream }
			onCreateNew = { onCreateNew }
			onGoLive = { onGoLive }
			open = { open }
			selectStream = { selectStream }
			sessionData = { sessionData }
			setUploadInProgressModal = { setUploadInProgressModal }
			showCurrentStreamMenu = { showCurrentStreamMenu }
			streams = { streams }
			uploadInProgressModal = { uploadInProgressModal }
			uploadType = { uploadType }
			videoModal = { openVideoModalExternal }
		/>
	);
};

export default component;
