import { Loader, Menu, RingProgress } from "@mantine/core";
import { capitalize }                 from "lodash";
import Link                           from "next/link";
import { useRouter }                  from "next/router";
import { useEffect }                  from "react";
import { useTranslation }             from "react-i18next";

import { storeEvent } from "_analytics/index";
import {
	uploadingStates,
	useGetInProgressVideoDetails,
	useSetUploadNotification,
	useSetVideoModal
} from "_common/hooks/uploadVideo";
import { shortenNumber } from "_common/utils";
import Icon              from "_components/atoms/icon";
import Image             from "_components/atoms/image";
import Render            from "_components/atoms/render";
import Typography        from "_components/atoms/typography";
import CoinsCount        from "_components/molecules/coinsCount";
import ConfirmModal      from "_components/molecules/confirmModal";
import AppearanceMenu    from "_components/molecules/menu/appearanceMenu";
import UploadMenuItem    from "_components/molecules/uploadMenuItem";
import useStyles         from "_styles/organisms/header";

import VideoUploadStatus from "./videoUploadStatus";

const component = ( {
	userProfile,
	handleClose,
	isRtmpPage,
	btnRef,
	isUploadingVideo,
	darkMode,
	percentCompleted,
	isProcessing,
	uploadType,
	inProgressVideoDetails,
	handleCloseByAction,
	isDeviceTheme,
	onThemeChange,
	isLogout,
	isCreatorDashboard,
	menuOpen,
	setMenuOpen,
	menuStep,
	setMenuStep,
	anchorEl
} ) => {
	/* we are not showing profile picture in webview but
  this could potentially change in designs in the future */
	const inProgressItem        = useGetInProgressVideoDetails ();
	const router                = useRouter ();
	const setUploadNotification = useSetUploadNotification ();
	const setVideoModal         = useSetVideoModal ();

	const clearNotification = () => {
		setUploadNotification ( null );
	};

	const onView = () => {
		const type = inProgressItem.type === "reel" ? "reels" : "videos";

		clearNotification ();

		router.push ( `/profile/${ userProfile?.id }?tab=${ type }` );
	};

	const onReUploadVideo = () => {
		handleClose ();
		setVideoModal ( true );
		clearNotification ();
	};

	const onClear = () => {
		clearNotification ();
		setTimeout ( () => {
			handleClose ();
		}, 200 );
	};

	useEffect ( () => {
		if ( isUploadingVideo === uploadingStates.UPLOAD_FINISHED ) {
			setUploadNotification ( {
				acceptBtnText: {
					title  : "View",
					action : onView
				},
				rejectBtnText: {
					title  : "Clear",
					action : onClear
				},
				iconName : "tickFilled",
				title    : `${ capitalize ( uploadType ) } uploaded & currently processing. Visit profile for latest status`
			} );

			return;
		}

		if ( isUploadingVideo === uploadingStates.UPLOAD_ERRORED ) {
			setUploadNotification ( {
				acceptBtnText: {
					title  : "Re-Upload",
					action : onReUploadVideo
				},
				rejectBtnText: {
					title  : "Clear",
					action : onClear
				},
				iconName : "crossRed",
				title    : `${ capitalize ( uploadType ) } Upload Failed`
			} );

			return;
		}

	}, [ isUploadingVideo ] );

	const { classes } = useStyles ( { isLogout, isMenuOpen: !!anchorEl, isCreatorDashboard } );

	if ( isLogout ) {
		return (
			<Menu
				closeOnClickOutside
				closeOnEscape
				keepMounted
				classNames = { { dropdown: classes.mainMenuContainer, itemLabel: classes.itemLabel } }
				position = "bottom-end"
				radius = "lg"
				styles = { { itemLabel: { display: "flex", justifyContent: "start" }, item: { borderRadius: "0" } } }
			>
				<Menu.Target>
					<div className = { classes.moonIconContainer }>
						<Icon
							className = { classes.themeIcon }
							name = "threeDotMenu"
						/>
					</div>
				</Menu.Target>

				<Menu.Dropdown>

					<AppearanceMenu
						hideBackBtn
						darkMode = { darkMode }
						isDeviceTheme = { isDeviceTheme }
						onThemeChange = { onThemeChange }
					/>

				</Menu.Dropdown>
			</Menu>

		);
	}

	const appearanceMenu = (
		<Menu.Item
			className = { classes.appearenceContainer }
			onClick = { () => {
				handleClose ( "appearance" );
				setMenuStep ( "appearance" );
			} }
		>
			<div className = { classes.appearenceSubContainer }>
				<Icon
					className = { classes.svgIcon }
					name = "halfMoon"
				/>

				<Typography
					className = "leftSpacing"
					title = { "Appearance" }
					variant = "title"
					weight = { 500 }
				/>

			</div>
		</Menu.Item>
	);

	return (
		<Menu
			closeOnClickOutside
			closeOnEscape
			keepMounted
			classNames = { {
				dropdown  : classes.mainMenuContainer,
				itemLabel : classes.itemLabel,
				item      : classes.item
			} }
			closeOnItemClick = { menuStep !== "mainMenu" }
			onChange = { () => setMenuOpen ( true ) }
			onClose = { () => setMenuOpen ( false ) }
			onOpen = { () => setMenuStep ( "mainMenu" ) }
			opened = { menuOpen }
			position = "bottom-end"
			radius = { "lg" }
			styles = { { itemLabel: { display: "flex", justifyContent: "start" }, item: { borderRadius: "0" } } }
			zIndex = { 1115 }
		>
			<Menu.Target
				ref = { btnRef }
				className = { classes.menuContainer }
			>
				<div>
					<Render condition = { isUploadingVideo === uploadingStates.UPLOADING }>

						<div className = { classes.circularUpload }>
							<CircularProgress
								isProcessing = { isProcessing }
								percentCompleted = { percentCompleted }
							/>
						</div>
					</Render>

					<Render condition = { isUploadingVideo !== uploadingStates.UPLOADING }>
						<div className = { classes.avatarContainer }>
							<Image
								className = { classes.img }
								placeholder = { "/images/rectangle-placeholder.webp" }
								src = { userProfile?.photo || "" }
								type = "ldpi"
							/>
						</div>
					</Render>
				</div>

			</Menu.Target>

			<Menu.Dropdown>
				<div>
					{
						menuStep === "mainMenu" ? (
							<div className = { classes.menuWrapper }>
								{
									isRtmpPage ? (
										<RtmpPageMenu
											appearanceMenu = { appearanceMenu }
											handleClose = { handleClose }
											storeEvent = { storeEvent }
											userProfile = { userProfile }
										/>
									) : (
										<div>
											{
												isUploadingVideo !== uploadingStates.UPLOADING ? (
													<VideoUploadStatus
														feedId = { inProgressVideoDetails?.feedId }
														onPerformAction = { handleCloseByAction }
													/>
												) : null
											}

											<Render condition = { isUploadingVideo === uploadingStates.UPLOADING || inProgressVideoDetails?.status === "INITIATED" }>
												<div className = { classes.linearProgressContainer }>
													<UploadMenuItem
														hideThumbnailFromAvatarMenu
														progress = { percentCompleted }
													/>
												</div>
											</Render>

											<ViewProfileMenu
												handleClose = { handleClose }
												storeEvent = { storeEvent }
												userProfile = { userProfile }
											/>

											<UserAssetsContainer
												shortenNumber = { shortenNumber }
												userProfile = { userProfile }
											/>

											<SettingsMenu handleClose = { handleClose }
												storeEvent = { storeEvent }
											/>

											<DashboardMenu />

											{appearanceMenu}

										</div>
									)
								}

								<LogoutBtn
									handleClose = { handleClose }
									isUploadingVideo = { isUploadingVideo }
									uploadType = { uploadType }
								/>

							</div>
						) : null
					}

					{menuStep === "appearance" ? (
						<AppearanceMenu
							darkMode = { darkMode }
							isDeviceTheme = { isDeviceTheme }
							onClickBack = { () => {
								setMenuStep ( "mainMenu" );
							} }
							onThemeChange = { onThemeChange }
						/>
					) : null}
				</div>
			</Menu.Dropdown>

		</Menu>
	);
};

export default component;

function CircularProgress ( { isProcessing, percentCompleted } ) {
	const { classes, theme } = useStyles ( undefined, { name: "circularProgress" } );
	const uploadIcon         = (
		<Icon
			sameInBothTheme
			className = { classes.uploadSvgIcon }
			name = "fileUploadArrowIcon"
			size = { 15 }
		>
		</Icon>
	);

	if ( isProcessing )
		return (
			<div style = { { height: "45px", width: "45px" } }>
				<Loader
					className = { classes.loader }
					size = { 65 }
				/>

				{uploadIcon}
			</div>
		);

	return (
		<div style = { { height: "45px", width: "45px" } }>
			<RingProgress
				className = { classes.ringProgress }
				classNames = { { svg: classes.ringSvg } }
				rootColor = { theme.other.paletteNew.darkModeWhite20 }
				sections = { [ {
					value : percentCompleted,
					color : theme.other.paletteNew.primaryDarkBlue
				} ] }
				size = { 45 }
				thickness = { 4 }
			/>

			{uploadIcon}
		</div>

	);
}

function LogoutBtn ( {
	isUploadingVideo,
	uploadType,
	handleClose
} ) {
	const { classes } = useStyles ();
	const { t }       = useTranslation ();

	const dialogText = isUploadingVideo === uploadingStates.UPLOADING ?
		"Are you sure, you want to logout? Your progress will be lost." :
		"Are you sure, you want to logout? You can easily log back in anytime you wish to.";

	const buttonComponent = (
		<Menu.Item className = { classes.logoutContainer }>
			<Typography
				className = "leftSpacing"
				title = { t ( "header_logout" ) }
				variant = "title"
				weight = "500"
			/>
		</Menu.Item>
	);

	return (
		<ConfirmModal
			acceptButtonText = "Logout"
			buttonComponent = { buttonComponent }
			closeAfterActionPreformed = { true }
			dialogText = { dialogText }
			dialogTitle = { isUploadingVideo === uploadingStates.UPLOADING ? `${ uploadType } Upload in Progress` : "Logout?" }
			fromProfileMenu = { true }
			performAction = { () => {
				handleClose ( "logout" );
			} }
			rejectButtonText = "Cancel"
		/>
	);
}

function RtmpPageMenu ( {
	storeEvent,
	handleClose,
	userProfile,
	appearanceMenu
} ) {

	const buttonComponent = (
		<RtmpMenuBtnComponent
			handleClose = { handleClose }
			storeEvent = { storeEvent }
			userProfile = { userProfile }
		/>
	);

	return (
		<>
			<ConfirmModal
				buttonComponent = { buttonComponent }
				dialogText = "Are you sure you want to exit this page?"
				fromProfileMenu = { true }
				performAction = { () => handleClose ( "profile" ) }
			/>

			{appearanceMenu}
		</>
	);
}

function RtmpMenuBtnComponent ( {
	storeEvent,
	handleClose,
	userProfile
} ) {
	const { classes } = useStyles ();

	return (
		<div>
			<Menu.Item className = { classes.appearenceContainer }
				onClick = { () => {
					storeEvent ( "Profile", "View", "Header_Drop_Down" );
					handleClose ( "profile" );
				} }
			>
				<div className = { classes.profilePicContainer }>
					<Image
						className = { classes.miniProfilePic }
						src = { userProfile?.photo }
						type = "ldpi"
					/>

					<div className = { classes.profileTextContainer }>
						<Typography
							title = { userProfile?.name }
							variant = "title"
							weight = { 500 }
						/>

						<p className = { classes.viewProfileText }>View Profile</p>
					</div>
				</div>
			</Menu.Item>

			<Menu.Item
				className = { classes.appearenceContainer }
				onClick = { () => {
					storeEvent ( "Profile", "View", "Header_Drop_Down" );
					handleClose ( "settings" );
				} }
			>
				<Icon className = { classes.svgIcon }
					name = "settings"
				/>

				{
					/* <SettingsIcon className = { classes.svgIcon } /> */
				}

				<Typography
					className = "leftSpacing"
					title = "Settings"
					variant = "title"
					weight = { "500" }
				/>
			</Menu.Item>
		</div>
	);
}

function ViewProfileMenu ( { storeEvent, handleClose, userProfile } ) {
	const { classes } = useStyles ();

	return (
		<Menu.Item className = { classes.viewProfile }
			onClick = { () => {
				storeEvent ( "Profile", "View", "Header_Drop_Down" );
				handleClose ( "profile" );
			} }
		>
			<div className = { classes.profilePicContainer }>
				<Image className = { classes.miniProfilePic }
					src = { userProfile?.photo }
					type = "ldpi"
				/>

				<div className = { classes.profileTextContainer }>
					<Typography title = { userProfile?.name }
						variant = "title"
						weight = "500"
					/>

					<p className = { classes.viewProfileText }>View Profile</p>
				</div>
			</div>
		</Menu.Item>
	);
}

function UserAssetsContainer ( { userProfile, shortenNumber } ) {
	const { classes } = useStyles ();

	return (
		<Menu.Item className = { classes.userAssetsContainet }>

			<CoinsCount coinsCount = { userProfile?.totalPoints }
				containerClassName = { classes.mr2 }
			/>

			<div className = { classes.userGemsContainer }>
				<Typography className = "rightSpacing"
					style = { {
						color: "white"
					} }
					title = { shortenNumber ( ( userProfile?.gems ) ) || 0 }
					variant = "title"
					weight = "500"
				/>

				<span className = { classes.diamondIconContainer }>
					<Icon sameInBothTheme
						className = { classes.icon }
						name = "diamond"
					>
					</Icon>
				</span>
			</div>

		</Menu.Item>
	);
}

function SettingsMenu ( { storeEvent, handleClose } ) {
	const { classes } = useStyles ();

	return (
		<Menu.Item className = { classes.appearenceContainer }
			onClick = { () => {
				storeEvent ( "Profile", "View", "Header_Drop_Down" );
				handleClose ( "settings" );
			} }
		>

			<Icon className = { classes.svgIcon }
				name = "settings"
			/>

			<Typography className = "leftSpacing"
				title = "Settings"
				variant = "title"
				weight = "500"
			/>

		</Menu.Item>
	);
}

function DashboardMenu () {

	const { classes } = useStyles ();

	return (
		<Menu.Item className = { classes.appearenceContainer }>
			<Link passHref
				href = "/creator-dashboard"
				rel = "noopener noreferrer"
				target = "_blank"
			>
				<div className = { classes.appearenceSubContainer }>
					<Icon className = { classes.svgIcon }
						name = "streamingDashboardIconUnfilled"
					/>

					<Typography className = "fontBold leftSpacing"
						title = { "Rooter Studio" }
						variant = "title"
					/>
				</div>
			</Link>

		</Menu.Item>
	);
}
