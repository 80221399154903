import { Menu } from "@mantine/core";

import Icon       from "_components/atoms/icon";
import Typography from "_components/atoms/typography";
import useStyles  from "_styles/molecules/menu/appearanceMenu";

const themeMenuList = [
	{ name: "Use Device theme", key: "device" },
	{ name: "Dark Mode", key: "dark" },
	{ name: "Light Mode", key: "light" }
];

const component = ( {
	onThemeChange,
	isDeviceTheme,
	darkMode,
	hideBackBtn,
	onClickBack
} ) => {

	const { classes } = useStyles ( undefined, { name: "styles" } );

	return (
		<div className = { classes.themeMenuContainer }>

			<div className = { classes.headerWrapper }>
				{
					hideBackBtn ?
						null : (
							<Icon
								className = { classes.backIcon }
								name = "backArrow"
								onClick = { onClickBack }

							/>
						)
				}

				<Typography
					className = "leftSpacing"
					color = "primary"
					size = { "md" }
					title = "Appearance"
					weight = { "500" }
				/>

			</div>

			{
				themeMenuList.map ( item => {
					const isSelected = ( item.key === "device" && isDeviceTheme ) || ( item.key == "dark" && darkMode && !isDeviceTheme ) || ( item.key == "light" && !darkMode && !isDeviceTheme );

					return (
						<Menu.Item
							key = { item.key }
							className = { `${ classes.themeMenuItem } ${ isSelected ? classes.activeMenu : "" }` }
							onClick = { () => {
								!isSelected && onThemeChange ( item.key );
							} }
							rightSection = {
								isSelected ? (
									<Icon
										className = { classes.tickIcon }
										name = "tickFilled"
									/>
								)
									: null
							}
						>
							<Typography
								className = "leftSpacing"
								title = { item.name }
							/>

						</Menu.Item>
					);
				}
				)
			}

		</div>
	);
};

export default component;
