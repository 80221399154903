import { useColorScheme } from "@mantine/hooks";
import { useRouter }      from "next/router";
import {
	useEffect,
	useRef,
	useState
} from "react";

import { useSetLoggedIn }      from "_common/hooks/auth";
import {
	useGetDarkMode,
	useGetDeviceTheme,
	useSetDarkMode,
	useSetDeviceTheme,
	useSetOpenUserSettingModal,
	useSetUserOnBoardingAborted
} from "_common/hooks/global";
import {
	uploadingStates,
	useGetIsUploadingVideo,
	useGetPercentCompletedAtom,
	useGetUploadNotification,
	useGetUploadSizeInfoAtom,
	useGetUploadTypeAtom
} from "_common/hooks/uploadVideo";
import {
	useGetInProgressVideoDetails,
	useSetPercentCompletedAtom,
	useSetUploadSizeInfoAtom
} from "_common/hooks/uploadVideo";
import {
	isCreatorDashboardPage
} from "_common/utils";
import { logoutUser } from "_components/pages/auth/apis";

import Presentation from "./presentation";

// import remoteConfigInstance                    from "_services/firebase/fetchRemoteConfig";

const Component = ( {
	userProfile,
	outsideAnchorEl,
	isLogout,
	onCloseOutside,
	hideBackBtn
} ) => {
	const colorScheme               = useColorScheme ();
	const setLoggedIn               = useSetLoggedIn ();
	const toggleDarkMode            = useSetDarkMode ();
	const toggleDeviceTheme         = useSetDeviceTheme ();
	const toggleUserSettingsPopup   = useSetOpenUserSettingModal ();
	const [ menuOpen, setMenuOpen ] = useState ( false );
	const [ menuStep, setMenuStep ] = useState ( "mainMenu" );
	const uploadNotification        = useGetUploadNotification ();

	const router   = useRouter ();
	const btnRef   = useRef ();
	const timerRef = useRef ( null );

	const isUploadingVideo = useGetIsUploadingVideo ();
	let percentCompleted   = useGetPercentCompletedAtom ();

	let setPercentCompleted        = useSetPercentCompletedAtom ();
	const uploadType               = useGetUploadTypeAtom ();
	const uploadSizeInfo           = useGetUploadSizeInfoAtom ();
	const setUploadSizeInfo        = useSetUploadSizeInfoAtom ();
	const isDeviceTheme            = useGetDeviceTheme ();
	const darkMode                 = useGetDarkMode ();
	const setUserOnBoardingAborted = useSetUserOnBoardingAborted ();
	const inProgressVideoDetails   = useGetInProgressVideoDetails ();

	let loaded = uploadSizeInfo?.progressEventLoaded ? Math.floor ( uploadSizeInfo.progressEventLoaded / 1000000 ) : 0;
	let total  = uploadSizeInfo?.progressEventTotal ? Math.floor ( uploadSizeInfo?.progressEventTotal / 1000000 ) : 0;

	useEffect ( () => {
		if ( inProgressVideoDetails?.type === "direct" ) {
			const percent      = inProgressVideoDetails.verified ? 100 : 0;
			const uploadedSize = inProgressVideoDetails.verified ? inProgressVideoDetails.clientFileSize : 0;

			setPercentCompleted ( percent );
			setUploadSizeInfo ( { progressEventLoaded: uploadedSize, progressEventTotal: inProgressVideoDetails.clientFileSize } );

			btnRef.current.click ();

			return;
		}

		if ( !inProgressVideoDetails?.parts?.length || ( inProgressVideoDetails?.status !== "INITIATED" && isUploadingVideo !== uploadingStates.UPLOADING ) )
			return;

		let totalUploadedCunksSize = 0;
		let totalChunksSize        = 0;

		inProgressVideoDetails.parts.forEach ( item => {
			if ( item.verified ) {
				totalUploadedCunksSize += item.clientChunkSize;
			}
			totalChunksSize += item.clientChunkSize;
		} )?.length || 0;

		const percent = ( totalUploadedCunksSize / totalChunksSize ) * 100;

		setPercentCompleted ( percent );
		setUploadSizeInfo ( { progressEventLoaded: totalUploadedCunksSize, progressEventTotal: totalChunksSize } );
		btnRef.current.click ();
	}, [ inProgressVideoDetails, isUploadingVideo ] );

	const isCreatorDashboard = isCreatorDashboardPage ( { router } );

	/* const [ sportsFanList, setSportsFanList ] = useState ( null );

  const fetchSportsFanList = async () => {

    const remoteConfigList = await remoteConfigInstance.getData ( "sportx", "creator_dashboard_access_list" );

    setSportsFanList ( remoteConfigList );

  };*/

	//  checking from remote config if sportsFan exist
	/* const userExistInIdListFn = () => {
    if ( !sportsFanList )
      return;
    if ( sportsFanList.find ( sportsFanId => parseInt ( sportsFanId ) === parseInt ( userProfile?.id ) ) ) {
      return true;
    }

    return false;
  };*/

	/* useEffect ( () => {
    if ( !userProfile?.id )
      return;

    fetchSportsFanList ();

  }, [ userProfile ] );*/

	useEffect ( () => {
		if ( uploadNotification ) {
			setMenuOpen ( true );
		}

	}, [ uploadNotification?.title ] );

	useEffect ( () => {
		if ( isDeviceTheme && colorScheme ) {
			toggleDarkMode ( colorScheme === "dark" );
		}
	}, [ colorScheme, isDeviceTheme ] );

	useEffect ( () => {
		if ( isUploadingVideo !== uploadingStates.UPLOADING ) {
			if ( timerRef.current )
				clearTimeout ( timerRef.current );

			return;
		}

		btnRef.current.click ();

		timerRef.current = setTimeout ( () => {
			setMenuOpen ( false );
		}, 5000 );

		return () => clearTimeout ( timerRef.current );
	}, [ isUploadingVideo ] );

	const handleClose = async type => {
		switch ( type ) {
			case "profile": {
				const profileLink = userProfile && `/profile/${ userProfile.id }`;

				setMenuOpen ( false );
				router.push ( profileLink );
				break;
			}

			case "settings": {
				const profileLink = userProfile && `/profile/${ userProfile.id }`;

				setMenuOpen ( false );
				router.push ( { pathname: profileLink, query: { fromMenu: true } } );
				toggleUserSettingsPopup ( true );
				break;
			}

			case "logout": {
				await logoutUser ( { isFrom: "Header", forceLogout: false } );
				setMenuOpen ( false );
				setLoggedIn ( false );
				setUserOnBoardingAborted ( false );
				router.reload ();

				break;
			}

			case "home": {
				router.push ( "/" );
				break;
			}

			default:
				break;
		}

		if ( isLogout )
			onCloseOutside ();
	};

	const isRtmpPage = router && router.pathname.includes ( "/studio" );

	const themeChangeHandler = key => {
		if ( key === "device" ) {
			toggleDeviceTheme ( !isDeviceTheme );

			return;
		}

		if ( key === "light" )
			toggleDarkMode ( false );

		if ( key === "dark" )
			toggleDarkMode ( true );

		toggleDeviceTheme ( false );
	};

	const isProcessing = loaded === total || ( total && loaded === 0 );

	return (
		<Presentation
			btnRef = { btnRef }
			darkMode = { darkMode }
			handleClose = { handleClose }
			hideBackBtn = { hideBackBtn }
			inProgressVideoDetails = { inProgressVideoDetails }
			isCreatorDashboard = { isCreatorDashboard }
			isDeviceTheme = { isDeviceTheme }
			isLogout = { isLogout }
			isProcessing = { isProcessing }
			isRtmpPage = { isRtmpPage }
			isUploadingVideo = { isUploadingVideo }
			loaded = { loaded }
			menuOpen = { menuOpen }
			menuStep = { menuStep }
			onCloseOutside = { onCloseOutside }
			onThemeChange = { themeChangeHandler }
			outsideAnchorEl = { outsideAnchorEl }
			percentCompleted = { percentCompleted }
			setMenuOpen = { setMenuOpen }
			setMenuStep = { setMenuStep }
			total = { total }
			uploadType = { uploadType }
			userProfile = { userProfile }
		/>
	);

};

export default Component;
