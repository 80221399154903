import { useState } from "react";

import { useGetLoggedIn, useGetProfileId }          from "_common/hooks/auth";
import logger                                       from "_common/logger";
import Toast                                        from "_common/toast";
import GoLiveMenuButton                             from "_components/molecules/goLiveMenu";
import { isValidStream }                            from "_components/pages/studio/utils";
import { getBroadcastSession, verifySessionStatus } from "_services/api/broadcast";

const CreateButton = ( { buttonClass } ) => {
	const log = logger ( { file: "components/organisms/navigationMenu/container" } );

	const [ isFetchingSessionStatus, setIsFetchingSessionStatus ] = useState ( false );
	const [ showCurrentStreamMenu, setShowCurrentStreamMenu ]     = useState ( false );
	const [ multipleStreamEnabled, setMultipleStreamEnabled ]     = useState ( false );
	const [ sessionData, setSessionData ]                         = useState ( false );

	const isLoggedIn = useGetLoggedIn ();
	const userId     = useGetProfileId ();

	const __verifySessionStatus = async () => {
		if ( !isLoggedIn )
			return;

		try {
			setIsFetchingSessionStatus ( true );
			const result = await verifySessionStatus ( { userId } );

			log.debug ( { result }, "got result for verifySessionStatus call" );
			/*
       * Check if the user has multi stream capability
       * if yes, give an option to open a current session
       * or create a new one
       */
			if ( result?.multipleStreamEnabled ) {
				setIsFetchingSessionStatus ( false );

				if ( result?.sessionAlreadyExists )
					setShowCurrentStreamMenu ( true );
				else
					setShowCurrentStreamMenu ( false );

				return setMultipleStreamEnabled ( result.multipleStreamEnabled );
			}

			if ( result?.multipleStreamEnabled && result?.sessionAlreadyExists ) {
				setIsFetchingSessionStatus ( false );

				return;

			}

			/* For non-multistream user */
			if ( result?.session?.id ) {
				const sdata = await getBroadcastSession ( result?.session.id );

				if ( isValidStream ( { sessionData: sdata, userId } ) ) {
					setSessionData ( sdata );

				}
			}

			setIsFetchingSessionStatus ( false );

			// other device active session
			// if ( result.isWebSession ) {

			// return Toast.info ( "You already have an active session from another device" );
			// }

			// active web session
			// if ( result.session ) {
			// const { id } = result?.session;
			// const sdata  = await getBroadcastSession ( id );

			// dispatch ( updateSession ( sdata ) );
			// dispatch ( setStreamLiveStatus ( true ) );
			// }
		} catch ( error ) {
			Toast.error ( error.displayMsg );
			setIsFetchingSessionStatus ( false );
		}
	};

	const onCreateNew = () => {
		onGoLive ();
	};

	const onGoLive = () => {
		if ( isLoggedIn ) {
			window.open ( "/studio", "_blank" );
		}
	};

	const onSelectStream = async stream => {

		// active web session
		const id = stream.id;
		// const sdata = await getBroadcastSession ( id );

		// dispatch ( updateSession ( sdata ) );
		onGoLive ( id );
	};

	return (
		<GoLiveMenuButton
			buttonClass = { buttonClass }
			checkSessionAPI = { __verifySessionStatus }
			isFetchingSessionStatus = { isFetchingSessionStatus }
			multiStream = { multipleStreamEnabled }
			onCreateNew = { onCreateNew }
			onGoLive = { onGoLive }
			selectStream = { onSelectStream }
			sessionData = { sessionData }
			showCurrentStreamMenu = { showCurrentStreamMenu }
		/>
	);
};

export default CreateButton;
