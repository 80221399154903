import { Divider } from "@mantine/core";

import { UPLOAD_TYPE_CONFIG, uploadingStates } from "_common/hooks/uploadVideo";
import Icon                                    from "_components/atoms/icon";
import LinearUploadProgress                    from "_components/atoms/linearProgress";
import Loader                                  from "_components/atoms/loader";
import Typography                              from "_components/atoms/typography";
import ConfirmModal                            from "_components/molecules/confirmModal";
import useStyles                               from "_styles/molecules/uploadMenuItem";

const component = props => {
	const {
		onCancelUpload,
		loaded,
		total,
		progress,
		uploadType,
		isResumeable,
		isResuming,
		onResumeUpload,
		isUploadingVideo,
		withDivider
	} = props;

	const { classes } = useStyles ( undefined, { name: "styles" } );
	const fileName    = localStorage.getItem ( "last_uploaded_video" );
	let title         = loaded === total ? `${ fileName || "" }` : `${ fileName || "" }`;

	const isProcessing = ( loaded === total && isUploadingVideo === uploadingStates.UPLOADING ) || ( total && loaded === 0 && isUploadingVideo === uploadingStates.UPLOADING );

	if ( isResumeable )
		title = "Upload Paused";

	return (
		<div>
			{/* {( isResumeable || isUploadingVideo ) && !hideThumbnailFromAvatarMenu ? (
				( thumbnailUrl ) ? (
					<Image className = { classes.image }
						src = { thumbnailUrl }
					/>
				)
					: (
						<UploadThumbnailSkeleton />
					)
			) : null} */}

			<div className = { classes.root }>

				<div className = { classes.rootContainer }>
					{/* {
								( thumbnailUrl && !hideThumbnailFromAvatarMenu ) ? (
									<Image className = { classes.image }
										src = { thumbnailUrl }
									/>
								) : null
						} */}

					<div className = { classes.detailsContainer }>

						<div className = { classes.header }>

							{
								uploadType === UPLOAD_TYPE_CONFIG.VIDEOS ? (
									<Icon
										sameInBothTheme
										className = { classes.infoIcon }
										name = "camera"
									/>
								) :
									( uploadType === UPLOAD_TYPE_CONFIG.REELS ? (
										<Icon
											sameInBothTheme
											className = { classes.infoIcon }
											name = "reels"
										/>
									) : null )

							}

							<div style = { { overflow: "hidden" } }>
								<Typography
									className = { classes.uploadingVideo }
									color = "primary"
									id = "scrollingContent"
									title = { title.substring ( 0, 100 ) }
									variant = "body1"
								/>

							</div>

							<Typography className = { classes.uploadSizeText }
								color = "secondary"
								title = { `${ loaded }MB/${ total }MB` }
								variant = "caption"
							/>

						</div>

						{isResumeable ? (
							<div className = { classes.resumeInfoContainer }>
								<Icon
									sameInBothTheme
									className = { classes.infoIcon }
									name = "infoFilled"
								/>

								<Typography
									className = { classes.infoText }
									color = "secondary"
									title = { `Click on ‘Resume’ & select same ${ uploadType } to continue upload` }
									variant = "caption"
								/>
							</div>
						) : null}

						<div className = { classes.linearProgress }>
							<LinearUploadProgress
								color = "secondary"
								progress = { progress }
								variant = { isProcessing ? "indeterminate" : "determinate" }
							/>
						</div>

						<div className = { classes.actionsBtns }>
							{isResumeable ? (
								<div
									className = { classes.resumeBtnContainer }
									data-loading = { isResuming ? true : undefined }
									onClick = { () => isResuming ? null : onResumeUpload () }
								>
									{isResuming ? (
										<Loader
											noMargin
											color = { "#757575" }
											size = { "1.35rem" }
											style = { { marginRight: "0.75rem" } }
										/>
									) : (
										<Icon
											sameInBothTheme
											className = { classes.resumeIcon }
											name = { "refreshFilled" }
										/>
									)}

									<Typography
										className = { `${ classes.resumeBtn } ${ isResuming ? "loading" : "" }` }
										title = { isResuming ? "Resuming..." : "Resume" }
									/>
								</div>
							) : null}

							{( loaded === total && !isResumeable ) ? null : (
								<ConfirmModal
									closeAfterActionPreformed
									buttonComponent = { (
										<div
											className = { classes.cancelBtn }
										>
											Cancel
										</div>
									) }
									dialogText = { "Are you sure, you want to cancel upload? Your progress will be lost." }
									dialogTitle = { `${ uploadType } Upload in Progress` }
									fromProfileMenu = { true }
									performAction = { onCancelUpload }
								/>

							)}

						</div>

					</div>
				</div>

			</div>

			{withDivider && <Divider className = { classes.divider } />}
		</div>
	);
};

export default component;
