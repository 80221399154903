import Presentation from "./presentation";

const FetchMore = ( { isLoading, isDepleted, fetchMore } ) => {
	return (
		<Presentation
			fetchMore = { fetchMore }
			isDepleted = { isDepleted }
			isLoading = { isLoading }
		/>

	);
};

export default FetchMore;