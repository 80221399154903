import { shortenNumber } from "_common/utils";
import Icon              from "_components/atoms/icon";
import Typography        from "_components/atoms/typography";
import useStyles         from "_styles/molecules/coinsCount";

const component = ( { coinsCount, containerClassName } ) => {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	return (
		<div className = { `${ containerClassName } ${ classes.userCoinsContainer }` }>
			<Typography
				className = "rightSpacing colorWhite"
				title = { shortenNumber ( coinsCount ) }
			/>

			<span
				className = { classes.coinIconContainer }
			>
				<Icon sameInBothTheme
					className = { classes.icon }
					name = "rooterCoinIcon"
				/>
			</span>
		</div>
	);
};

export default component;